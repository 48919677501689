import { Fragment } from 'react';
import * as React from 'react';
import classnames from 'classnames';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash/get';
import { FormattedMessage } from 'dibs-react-intl';

// Components
import Close from 'dibs-icons/exports/legacy/Close';
import { SeoLink } from 'dibs-elements/exports/SeoLink';
import { SeoButtonComponent } from 'dibs-seo/exports/SeoButton';
import { Link } from 'dibs-elements/exports/Link';
import { QuickViewContext } from '../QuickViewProvider';

import { type QuickViewItem_item$data as QuickViewItemProp } from './__generated__/QuickViewItem_item.graphql';
import { type QuickViewItem_itemSearch$data as QuickViewItemSearchProp } from './__generated__/QuickViewItem_itemSearch.graphql';
import { QuickViewCategoryParagraph } from '../QuickViewCategoryParagraph';
import QuickViewCreators from '../QuickViewCreators';

// Styles
import styles from './styles.scss';

type QuickViewItemProps = {
    item: QuickViewItemProp | null;
    isQuickViewActive: boolean;
    itemSearch: QuickViewItemSearchProp | null | undefined;
    onViewDetailsClick?: () => void;
    isMobile?: boolean;
    isSbTile?: boolean;
    focusOnActive?: boolean;
};

type QuickViewItemState = {
    isQuickViewActive: boolean;
};

type Paragraph = {
    key: string;
    text: string | null;
    mobileText: string | null;
};

export const copy = {
    locatedIn: (
        <FormattedMessage id="dc.searchProductTile.locatedIn" defaultMessage="Located in">
            {msg => <Fragment>{msg}</Fragment>}
        </FormattedMessage>
    ),
    materials: (
        <FormattedMessage id="dc.searchProductTile.materials" defaultMessage="Materials">
            {msg => <Fragment>{msg}</Fragment>}
        </FormattedMessage>
    ),
    viewDetails: (
        <FormattedMessage id="dc.searchProductTile.viewDetails" defaultMessage="View Full Details">
            {msg => <Fragment>{msg}</Fragment>}
        </FormattedMessage>
    ),
};

class QuickViewItem extends React.Component<QuickViewItemProps, QuickViewItemState> {
    constructor(props: QuickViewItemProps) {
        super(props);

        this.state = {
            isQuickViewActive: props.isQuickViewActive,
        };
    }

    componentDidUpdate(prevProps: QuickViewItemProps): void {
        const { isQuickViewActive, focusOnActive } = this.props;
        if (prevProps.isQuickViewActive !== isQuickViewActive) {
            this.setState({ isQuickViewActive });
            if (focusOnActive) {
                this.shouldFocus = true;
            }
        }
    }
    // store as instance property, not state, bc changes should not rerender
    shouldFocus: boolean = false;
    static contextType = QuickViewContext;
    declare context: React.ContextType<typeof QuickViewContext>;

    render(): React.ReactNode {
        const { item, itemSearch, onViewDetailsClick, isMobile, isSbTile } = this.props;
        const { isQuickViewActive } = this.state;
        /* istanbul ignore if */
        if (!item?.quickViewDisplay) {
            return null;
        }

        const onClose = (): void => this.context?.closeQuickView?.();

        const mobileTitle = get(item, 'quickViewDisplay.mobileTitle');
        const title = get(item, isSbTile ? 'title' : 'quickViewDisplay.title');
        const paragraphs = get(item, 'quickViewDisplay.paragraphs') || [];
        const quickViewClass = classnames(styles.wrapper, {
            [styles.isActive]: isQuickViewActive,
            [styles.isSbTile]: isSbTile,
        });
        const itemClass = classnames(styles.item, {
            [styles.isSbTile]: isSbTile,
        });

        const paragraphsMap = paragraphs.reduce(
            (map: { [x: string]: string | null }, paragraph: Paragraph) => {
                const { key, text, mobileText } = paragraph;
                map[key] = isMobile ? mobileText : text;
                return map;
            },
            {}
        );

        const { location, description, materials, attributes } = paragraphsMap;
        const linkData = {
            path: get(item, 'linkData.path'),
            isLinkable: false,
        };

        const quickViewCloseClassNames = classnames(styles.close);

        return (
            <div
                className={quickViewClass}
                tabIndex={-1}
                ref={node => {
                    if (node && this.shouldFocus) {
                        node.focus();
                        this.shouldFocus = false;
                    }
                }}
            >
                <div className={itemClass}>
                    <Link
                        ariaLabel="close quick view"
                        dataTn="quick-view-close"
                        className={quickViewCloseClassNames}
                        onClick={onClose}
                    >
                        <Close />
                    </Link>
                    {isSbTile ? (
                        <div className={styles.testContainer}>
                            <div className={styles.testParagraphs}>
                                {!isMobile && (
                                    <div data-tn="quick-view-title" className={styles.testTitle}>
                                        {title}
                                    </div>
                                )}
                                <QuickViewCreators
                                    className={styles.testCreator}
                                    quickViewDisplay={item?.quickViewDisplay}
                                />
                                {location && (
                                    <div data-tn="quick-view-location">
                                        {copy.locatedIn} {location}
                                    </div>
                                )}
                                {description && (
                                    <div
                                        data-tn="quick-view-description"
                                        className={styles.testDescription}
                                        dangerouslySetInnerHTML={{ __html: description }}
                                    />
                                )}
                                <QuickViewCategoryParagraph
                                    item={item}
                                    itemSearch={itemSearch}
                                    attributes={attributes}
                                    paragraphClassName={styles.testParagraph}
                                    categoryClassName={styles.testCategory}
                                />
                                {materials && (
                                    <div
                                        data-tn="quick-view-materials"
                                        className={styles.testParagraph}
                                    >
                                        {copy.materials}
                                        <p>{materials}</p>
                                    </div>
                                )}
                            </div>

                            {
                                <div>
                                    {isMobile ? (
                                        <SeoLink
                                            linkData={linkData}
                                            onClick={onViewDetailsClick}
                                            dataTn="quick-view-item-link"
                                        >
                                            {copy.viewDetails}
                                        </SeoLink>
                                    ) : (
                                        <SeoButtonComponent
                                            type="secondary"
                                            size="small"
                                            fullWidth
                                            linkData={linkData}
                                            onClick={onViewDetailsClick}
                                            dataTn="quick-view-item-button"
                                        >
                                            {copy.viewDetails}
                                        </SeoButtonComponent>
                                    )}
                                </div>
                            }
                        </div>
                    ) : (
                        <div className={styles.content}>
                            <div className={styles.title}>{isMobile ? mobileTitle : title}</div>
                            {!!paragraphs &&
                                paragraphs.map((paragraph: Paragraph) => (
                                    <div className={styles.paragraph} key={paragraph.key}>
                                        {isMobile ? paragraph.mobileText : paragraph.text}
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default createFragmentContainer(QuickViewItem, {
    itemSearch: graphql`
        fragment QuickViewItem_itemSearch on ItemSearchQueryConnection {
            ...QuickViewCategoryParagraph_itemSearch
        }
    `,
    item: graphql`
        fragment QuickViewItem_item on Item {
            title
            linkData {
                path
            }
            quickViewDisplay {
                title
                mobileTitle: title(formatType: MOBILE)
                paragraphs {
                    key
                    text
                    mobileText: text(formatType: MOBILE)
                }
                ...QuickViewCreators_quickViewDisplay
            }
            ...QuickViewCategoryParagraph_item
        }
    `,
});
