import { type ComponentProps, type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { Badge } from 'dibs-elements/exports/Badge';

import dibsCss from 'dibs-css';

export type BadgeBackgroundColor = ComponentProps<typeof Badge>['backgroundColor'];

type FreeShippingProps = React.HTMLAttributes<HTMLDivElement> & {
    backgroundColor?: BadgeBackgroundColor;
    reduceMargins?: boolean;
};

export const FreeShippingBadge: FC<FreeShippingProps> = ({
    backgroundColor,
    reduceMargins,
    ...props
}) => {
    return (
        <div
            {...props}
            className={reduceMargins ? dibsCss.mb2px : dibsCss.mbXsmall}
            data-tn="free-shipping"
        >
            <Badge type="pill" size="small-heavy" backgroundColor={backgroundColor}>
                <FormattedMessage
                    id="dc.searchProductTile.freeShipping"
                    defaultMessage="Free Shipping"
                />
            </Badge>
        </div>
    );
};
