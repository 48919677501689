import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import get from 'lodash/get';
import { FormattedMessage } from 'dibs-react-intl';
import Ruler from 'dibs-icons/exports/legacy/Ruler';
import { DEFAULT_MEASUREMENT_UNIT } from '../constants';

import styles from './ProductTileMeasurements.scss';

const ProductTileMeasurementsComponent = ({ item, measurementUnit, isMobile }) => {
    const measurement = get(item, 'measurement');
    const size = get(measurement, 'size') || {};
    const dimensionValues = (get(measurement, 'display') || [])
        .filter(({ unit }) => (unit ? unit === measurementUnit : true))
        .map(dimension => dimension.value);

    return size.value ? (
        <FormattedMessage
            id="dc.searchProductTile.sizeLabel"
            defaultMessage="Size: {size}"
            values={{
                size: size.value,
            }}
        />
    ) : (
        <Fragment>
            {!isMobile && dimensionValues[0] && <Ruler className={styles.rulerIcon} />}
            {dimensionValues[0]}
        </Fragment>
    );
};

ProductTileMeasurementsComponent.propTypes = {
    item: PropTypes.object,
    measurementUnit: PropTypes.string,
    isMobile: PropTypes.bool,
};

ProductTileMeasurementsComponent.defaultProps = {
    measurementUnit: DEFAULT_MEASUREMENT_UNIT,
};

export const ProductTileMeasurements = createFragmentContainer(ProductTileMeasurementsComponent, {
    item: graphql`
        fragment ProductTileMeasurements_item on Item {
            measurement {
                display(
                    addLabel: true
                    separator: " "
                    fields: [height, width, depth, diameter, length]
                ) {
                    unit
                    value
                }
                size {
                    value
                }
            }
        }
    `,
});
